import { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { Store } from '../Store';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import Bottom from "react-bootstrap/Bottom";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import MessageBox from '../component/MessageBox';
import { Link, useNavigate } from 'react-router-dom';
import CheckoutSteps from '../component/CheckoutSteps';
import NumberFormat from '../component/NumberFormat';
//import Product from "../component/Product";
//import axios from "axios";

export default function CartScreen() {
  const navigate = useNavigate();
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const {
    cart,
    cart: { cartItems },
  } = state;

  const updateCartHandler = async (item, quantity) => {
    //const { data } = await axios.get(`/api/products/${item.itemId}`);

    ctxDispatch({
      type: 'CART_ADD_ITEM',
      payload: { ...item, quantity },
    });
  };
  const removeItemHandler = (item) => {
    ctxDispatch({
      type: 'CART_REMOVE_ITEM',
      payload: item,
    });
  };
  const checkoutHandler = () => {
    // navigate('/?redirect=/placeorder');
    navigate('/placeorder');
  };
  const clearCartHandler = () => {
    if (window.confirm('آپ اس "کارٹ" کو ہٹانا چاہتے ہیں؟')) {
      ctxDispatch({
        type: 'CART_CLEAR',
      });
      localStorage.removeItem('cartItems');
      localStorage.removeItem('customerInfo');

      navigate('/signin');
    }
  };
  return (
    <div>
      <CheckoutSteps step1 step2 step3 step4 />
      <Helmet>
        <title>Shopping Cart</title>
      </Helmet>
      <div
        style={{
          display: 'flex',
        }}
      >
        <div style={{ display: 'inline', width: '60%' }}>
          <h1>Shopping Cart</h1>
        </div>
        <div
          style={{
            display: 'inline',
            textAlign: 'right',
            width: '40%',
          }}
        >
          <Button
            onClick={clearCartHandler}
            type="button"
            variant="primary"
            style={{ alignItems: 'right' }}
            className="btn-cart-clear"
          >
            Clear Cart
          </Button>
        </div>
      </div>
      <Row>
        <Col sm={12}>
          <Card>
            <Card.Body>
              <Card.Title>Customer Information</Card.Title>
              <Card.Text>
                <Row>
                  <Col xs={3}>
                    <strong>Name :</strong>
                  </Col>
                  <Col xs={9}>
                    <strong>
                      {cart.customerInfo.CsCode} - {cart.customerInfo.CsName}
                    </strong>
                  </Col>
                </Row>
                <Row>
                  <Col xs={3}>
                    <strong>Address :</strong>
                  </Col>
                  <Col xs={9}>{cart.customerInfo.CsAddrs}</Col>
                </Row>
                <Row>
                  <Col xs={3}>
                    <strong>CNIC :</strong>
                  </Col>
                  <Col xs={9}>{cart.customerInfo.CsCNIC}</Col>
                </Row>
                <Row>
                  <Col xs={3}>
                    <strong>NTN :</strong>
                  </Col>
                  <Col xs={9}>{cart.customerInfo.CsNTNO}</Col>
                </Row>
                <Row>
                  <Col xs={3}>
                    <strong>Market :</strong>
                  </Col>
                  <Col xs={9}>
                    <strong>{cart.customerInfo.MarketName}</strong>
                  </Col>
                </Row>
              </Card.Text>
              <Card.Link href="/customer">Change customer</Card.Link>
              {/* <Link to=""></Link> */}
            </Card.Body>
          </Card>
        </Col>
        <h5>Product Information</h5>
        <Col xs={18}>
          {cartItems.lenght === 0 ? (
            <MessageBox>
              {/* Cart is empty.  <Link to="/products">Go Shopping</Link> */}
              Cart is empty. <Card.Link to="/products">Go Shopping</Card.Link>
            </MessageBox>
          ) : (
            <ListGroup>
              {cartItems.map((item) => (
                <ListGroup.Item key={item.Id}>
                  <Row className="align-items-center">
                    <Col xs={3}>{item.ItemDesc}</Col>
                    <Col xs={1}>
                      <Button
                        onClick={() =>
                          updateCartHandler(item, item.quantity - 1)
                        }
                        variant="light"
                        disabled={item.quantity === 1}
                      >
                        <i className="fas fa-minus-circle"></i>
                      </Button>{' '}
                    </Col>
                    <Col xs={2} style={{ textAlign: 'right', padding: 0 }}>
                      {item.quantity}
                      {item.bonusqnty > 0 ? '+' + item.bonusqnty : ''}
                    </Col>
                    <Col xs={2}>
                      <Button
                        variant="light"
                        onClick={() =>
                          updateCartHandler(item, item.quantity + 1)
                        }
                      >
                        <i className="fas fa-plus-circle"></i>
                      </Button>
                    </Col>
                    <Col xs={2}>
                      Rs.{' '}
                      <NumberFormat
                        value={item.quantity * item.ItemRate}
                        decimal={2}
                      />
                      /-
                    </Col>
                    <Col xs={1}>
                      <Button
                        onClick={() => removeItemHandler(item)}
                        variant="light"
                      >
                        <i className="fas fa-trash"></i>
                      </Button>
                    </Col>
                  </Row>
                </ListGroup.Item>
              ))}
              <ListGroup.Item>
                <Card.Link href="/products">Change Products</Card.Link>
              </ListGroup.Item>
            </ListGroup>
          )}
        </Col>
        <Col sm={4}>
          <Card>
            <Card.Body>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <strong>
                    Total : ( {cartItems.length} Items {''}
                    {cartItems.reduce((a, c) => a + Number(c.quantity), 0)}
                    {'+'}
                    {cartItems.reduce(
                      (a, c) => a + Number(c.bonusqnty),
                      0
                    )}{' '}
                    Quantity
                    <strong>
                      {' '}
                      Rs. :{' '}
                      <NumberFormat
                        value={cartItems.reduce(
                          (a, c) => a + c.ItemRate * c.quantity,
                          0
                        )}
                        decimal={2}
                      ></NumberFormat>
                      /-
                    </strong>
                  </strong>
                  <ListGroup.Item>
                    <div className="d-grid">
                      <Button
                        onClick={checkoutHandler}
                        type="button"
                        variant="primary"
                        disabled={cartItems.lenght === 0}
                      >
                        Proceed to Checkout
                      </Button>
                    </div>
                  </ListGroup.Item>
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
