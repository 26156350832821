import axios from 'axios';
import React, { useContext, useEffect, useReducer } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate, useParams } from 'react-router-dom';
import LoadingBox from '../component/LoadingBox';
import MessageBox from '../component/LoadingBox';
import { Store } from '../Store';
import { getError } from '../util';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/esm/Button';
import NumberFormat from '../component/NumberFormat';

function reducer(state, action) {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true, error: '' };
    case 'FETCH_SUCCESS':
      return { ...state, loading: false, order: action.payload, error: '' };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}

export default function SummaryScreen() {
  const { state } = useContext(Store);
  const { userInfo } = state;
  const params = useParams();
  const navigate = useNavigate();
  const { id: orderId } = params;

  const [{ loading, error, order }, dispatch] = useReducer(reducer, {
    loading: true,
    order: {},
    error: '',
  });

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        dispatch({ type: 'FETCH_REQUEST' });
        const { data } = await axios.get(`/api/orders/summary/${orderId}`, {
          headers: { authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: 'FETCH_SUCCESS', payload: data });
      } catch (err) {
        dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
      }
    };

    if (!userInfo) {
      return navigate('/signin');
    }

    if (!order.id || (order.id && order.summary[0].BsRefNo !== orderId)) {
      fetchOrder();
    }
  }, [order, userInfo, orderId, navigate]);

  return loading ? (
    <LoadingBox></LoadingBox>
  ) : error ? (
    <MessageBox variant="danger">{error}</MessageBox>
  ) : (
    <div>
      <Helmet>
        <title>Summary Information {order.summary[0].BsRefNo}</title>
      </Helmet>
      <Button
        type="button"
        onClick={() => {
          navigate('/pendingorders');
        }}
        // disabled={cart.cartItems.length === 0}
      >
        New Summary
      </Button>

      <h6 className="my-3" style={{ color: 'green' }}>
        Summary successfully created & uploaded! 👍
      </h6>
      <h6 className="my-3" style={{ color: 'green' }}>
        <Row>
          <Col xs={8}>Generated Summary Number :</Col>
          <Col xs={4} style={{ textAlign: 'left' }}>
            {order.summary[0].BsRefNo}
          </Col>
        </Row>
      </h6>
      <h6 className="my-3" style={{ color: 'green' }}>
        <Row>
          <Col xs={8}>Generated Summary Date : </Col>
          <Col xs={4}>
            {order.summary[0].BsRefDate.toString()
              .slice(0, 10)
              .toString()
              .trim()}
          </Col>
        </Row>
      </h6>
      <h6 className="my-3" style={{ color: 'green' }}>
        <Row>
          <Col xs={8}>Number of Summaries : </Col>
          <Col xs={4}>
            {order.summary[0].NoOfSummaryGeneratedToday} /{' '}
            {order.summary[0].TotalNoOfSummaries}
          </Col>
        </Row>
      </h6>
      <Row>
        <Col xs={12}>
          <Card>
            <Card.Title
              style={{
                marginLeft: '25px',
              }}
            >
              Summary Orders
            </Card.Title>
            <Card.Body>
              <ListGroup variant="flush">
                <ListGroup.Item></ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col xs={3}>
                      <strong>Booking No.</strong>
                    </Col>
                    <Col xs={6}>
                      <strong>Customer</strong>
                    </Col>
                    <Col xs={3}>
                      <strong>Amount</strong>
                    </Col>
                  </Row>
                </ListGroup.Item>
                {order.summary.map((ord) => (
                  // <div key={order.BsId}>
                  <ListGroup.Item>
                    <Row className="align-items-center" key={ord.Id}>
                      <Col xs={3}>
                        <Row
                          style={{
                            marginLeft: '1px',
                          }}
                        >
                          {ord.BknghRefNo}
                        </Row>
                        <Row
                          style={{
                            marginLeft: '1px',
                          }}
                        >
                          {ord.BknghRefDate.toString()
                            .slice(0, 10)
                            .toString()
                            .trim()}
                        </Row>
                      </Col>
                      <Col xs={6}>{ord.CsName}</Col>
                      <Col xs={3}>
                        <NumberFormat
                          value={ord.Amount}
                          decimal={2}
                        ></NumberFormat>
                      </Col>
                    </Row>
                  </ListGroup.Item>

                  // </div>
                ))}
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/* <Row>
        <Col xs={12}>
          <Card>
            <Card.Title>Summary Items</Card.Title>
            <Card.Body>
              <ListGroup variant="flush">
                <ListGroup.Item></ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col xs={8}>
                      <strong>Items</strong>
                    </Col>
                    <Col xs={4}>
                      <strong>Quantity</strong>
                    </Col>
                  </Row>
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row> */}
    </div>
  );
}
