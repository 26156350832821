import axios from 'axios';
import React, { useContext, useEffect, useReducer } from 'react';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingBox from '../component/LoadingBox';
import MessageBox from '../component/MessageBox';
import { Store } from '../Store';
import { getError } from '../util';
import NumberFormat from '../component/NumberFormat';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return { ...state, todayitemsummary: action.payload, loading: false };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default function SummaryItemsScreen() {
  const { state } = useContext(Store);
  const { userInfo } = state;
  const navigate = useNavigate();

  const [{ loading, error, todayitemsummary }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  });

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: 'FETCH_REQUEST' });
      try {
        const { data } = await axios.get(
          `/api/orders/summaryitems/${userInfo.code}`,
          {
            headers: {
              authorization: `Bearer ${userInfo.token}`,
            },
          }
        );
        dispatch({ type: 'FETCH_SUCCESS', payload: data });
      } catch (error) {
        dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
      }
    };

    fetchData();
  }, [userInfo]);

  return (
    <div>
      <Helmet>
        <title>Today's Item Summary</title>
      </Helmet>
      <h1>Today's Item Summary</h1>
      {loading ? (
        <LoadingBox></LoadingBox>
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <table className="table">
          <thead>
            <tr>
              <th style={{ width: '40%' }}>Item Description</th>
              <th style={{ width: '20%' }}>Quantity</th>
              <th style={{ width: '10%', textAlign: 'right' }}>Tp Rate</th>
              <th style={{ width: '10%', textAlign: 'right' }}>Value</th>
            </tr>
          </thead>
          <tbody>
            {todayitemsummary.map((item) => (
              <tr key={item.Id}>
                <td style={{ width: '40%' }}>{item.ItemDesc}</td>
                <td style={{ width: '20%' }}>{item.Quantity}</td>
                <td style={{ textAlign: 'right', width: '10%' }}>
                  <NumberFormat value={item.TpRate} decimal={2}></NumberFormat>
                </td>
                <td style={{ textAlign: 'right', width: '10%' }}>
                  <NumberFormat value={item.Amount} decimal={2}></NumberFormat>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}
