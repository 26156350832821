import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/esm/Container';
import { Helmet } from 'react-helmet-async';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/esm/Form';
import Row from 'react-bootstrap/Row';
import Axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { Store } from '../Store';
import { toast } from 'react-toastify';
import { getError } from '../util';
import MessageBox from '../component/MessageBox';
import LoadingBox from '../component/LoadingBox';
import {
  BsCartPlusFill,
  BsCardChecklist,
  BsBasketFill,
  BsBoxes,
  BsCart4,
} from 'react-icons/bs';
import { AiFillAppstore } from 'react-icons/ai';
import { TbShoppingCartDiscount } from 'react-icons/tb';

import hello from '../assets/hello.gif';

export default function MainScreen() {
  const navigate = useNavigate();
  var [date, setDate] = useState(new Date());

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { compcode, cart, userInfo, customers } = state;
  const countCustomers = customers ? Object.keys(customers).length : 0;
  const btnHandler = (_btnDesc, _cmpCode) => {
    ctxDispatch({
      type: 'SAVE_COMPCODE',
      payload: _cmpCode,
    });
    localStorage.setItem('cmpcode', JSON.stringify(_cmpCode));

    navigate(`/${_btnDesc}`);
    // if (_btnDesc === 'customer') {
    //   navigate(`/customer`);
    // } else if (_btnDesc === 'pendingorders') {
    //   navigate(`/pendingorders`);
    // } else if (_btnDesc === 'orderhistory') {
    //   navigate(`/orderhistory`);
    // } else if (_btnDesc === 'summaryhistory') {
    //   navigate(`/summaryhistory`);
    // }
  };

  useEffect(() => {
    if (!userInfo || !customers || countCustomers === 0) {
      navigate('/signin');
    } else if (Object.keys(cart.customerInfo).length !== 0) {
      navigate('/products');
    } else {
      if (Object.keys(cart.customerInfo).length === 0) {
        ctxDispatch({
          type: 'SAVE_COMPCODE',
          payload: '',
        });
        localStorage.removeItem('cmpcode', JSON.stringify(''));
      }
    }
  }, [
    navigate,
    userInfo,
    customers,
    countCustomers,
    cart.customerInfo,
    compcode,
    ctxDispatch,
  ]);

  useEffect(() => {
    var timer = setInterval(() => setDate(new Date()), 1000);
    return function cleanup() {
      clearInterval(timer);
    };
  });
  // var macaddress = require('macaddress');
  // macaddress.one(function (err, mac) {
  //   alert('Mac address for this host: %s', mac);
  // });

  return (
    <Container className="small-container">
      <Helmet>
        <title>Madni Order App</title>
      </Helmet>
      <h6>
        <span
          style={{
            width: '100%',
            display: 'table-cell',
          }}
        >
          {date.toLocaleDateString()} {date.toLocaleTimeString()}
        </span>
        <span
          style={{
            width: '100%',
            display: 'table-cell',
          }}
        >
          v3.0.1
        </span>
      </h6>
      <div
        style={{
          // margin: '0 auto',
          backgroundColor: 'white',
          // alignItems: 'middle-center',
          textAlign: 'center',
        }}
      >
        {/* <img
          src={hello}
          style={{ width: '80%', margin: '0 auto', textAlign: 'center' }}
          alt="loading..."
        /> */}
        <h1 style={{ color: 'green' }}>
          Welcome {!userInfo ? '' : userInfo.name}
        </h1>
      </div>
      <Form>
        <div className="maindiv maindivnew">
          <Button
            className="mainbtn"
            onClick={() => btnHandler('customer', 'MC')}
          >
            <div>
              {' '}
              <BsCartPlusFill size={30} />
            </div>
            <Row>
              <strong>New Order / Cart</strong>
            </Row>
            <Row>
              <strong>( M.C )</strong>
            </Row>
          </Button>
          <Button
            className="mainbtn"
            onClick={() => btnHandler('customer', 'ME')}
          >
            <div>
              {' '}
              <BsCartPlusFill size={30} />
            </div>
            <Row>
              <strong>New Order / Cart( M.E )</strong>
            </Row>
            <Row>
              <strong>( M.E )</strong>
            </Row>
          </Button>
          {/* <Button
            className="mainbtn"
            onClick={() => btnHandler('customer', 'Y')}
          >
            <div>
              {' '}
              <BsCartPlusFill size={30} />
              <TbShoppingCartDiscount size={30} />
            </div>
            <strong>New Bonus Order / Cart</strong>
          </Button> */}
          {/* </div>
        <div className="maindiv maindivnew"> */}
          <Button
            className="mainbtn"
            onClick={() => btnHandler('pendingorders', '')}
          >
            <div>
              <BsCardChecklist size={30} />
            </div>
            <strong>New Summary</strong>
          </Button>
          {/* </div>
        <div className="maindiv maindivhistory"> */}
          {/* <Button
            className="mainbtn"
            onClick={() => btnHandler('orderhistory', '')}
            disabled
          >
            <div>
              <AiFillAppstore size={30} />
            </div>
            <strong>Order History</strong>
          </Button> */}
          {/* </div>
        <div className="maindiv maindivhistory"> */}
          {/* <Button
            className="mainbtn"
            onClick={() => btnHandler('summaryitems', '')}
            disabled
          >
            <div>
              <BsCart4 size={30} />
            </div>
            <strong>Today's Item Summary</strong>
          </Button> */}
          {/* <Button
            className="mainbtn"
            onClick={() => btnHandler('summaryhistory', '')}
            disabled
          >
            <div>
              <BsBoxes size={30} />
            </div>
            <strong>Summary History</strong>
          </Button> */}
        </div>
      </Form>
    </Container>
  );
}
