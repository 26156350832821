import { useState, useEffect, useReducer, useContext, useRef } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { useLocation, useNavigate } from 'react-router-dom';
import MessageBox from '../component/MessageBox';
import LoadingBox from '../component/LoadingBox';
import Customer from '../component/Customer';
//import data from '../data';
import { Store } from '../Store';
import axios from 'axios';
import logger from 'use-reducer-logger';
import { Helmet } from 'react-helmet-async';
import CheckoutSteps from '../component/CheckoutSteps';
import SearchBox from '../component/SearchBox';
import { ViewportList } from 'react-viewport-list';
import { List } from 'react-virtualized';

// const reducer = (state, action) => {
//   switch (action.type) {
//     case 'FETCH_REQUEST':
//       return { ...state, loading: true };
//     case 'FETCH_SUCCESS':
//       return { ...state, loading: false, customers: action.payload };
//     case 'FETCH_FAIL':
//       return { ...state, loading: false, error: action.payload };
//     default:
//       return state;
//   }
// };

function CustomerScreen() {
  const ref = useRef(null);
  const navigate = useNavigate();

  const { state } = useContext(Store);

  const { cart, userInfo, customers, compcode } = state;

  const countCustomers = Object.keys(customers).length;

  let filterdCustomers = Array([]);
  // const [{ loading, error, customers }, dispatch] = useReducer(
  //   logger(reducer),
  //   {
  //     customers: [],
  //     loading: true,
  //     error: '',
  //   }
  // );

  // useEffect(() => {
  //   const fetchData = async () => {
  //     dispatch({ type: 'FETCH_REQUEST' });
  //     try {
  //       const result = await axios.get(`/api/customers/${userInfo.code}`);
  //       dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
  //       localStorage.setItem('customers', JSON.stringify(result.data));
  //     } catch (err) {
  //       dispatch({ type: 'FETCH_FAIL', payload: err.message });
  //     }

  //     //setProducts(result.data);
  //   };
  //   fetchData();
  // }, []);
  useEffect(() => {
    if (!userInfo || !customers || countCustomers === 0) {
      navigate('/signin');
    }
  }, [
    navigate,
    userInfo,
    customers,
    countCustomers,
    cart.customerInfo,
    filterdCustomers,
  ]);

  if (compcode === 'ME') {
    filterdCustomers = customers.filter((customer) => {
      if (customer.CsMisc === 'Y') {
        return false;
      }
      // apply all other filters the same as above

      // Finally return true because record has satisfied all conditions
      return true;
    });
  } else {
    filterdCustomers = customers;
  }

  //setCmpCustomers(filterdCustomers);

  const renderCustomer = ({ index, key, style }) => (
    <div key={key} style={style} className="post">
      {/* <h3>{`${products[index].ItemDesc}-${products[index].Id}`}</h3>
        <p>{products[index].body}</p> */}
      <Customer customer={filterdCustomers[index]}></Customer>
    </div>
  );

  return (
    <div>
      <CheckoutSteps step1 step2></CheckoutSteps>
      <Helmet>
        <title>Customer Information</title>
      </Helmet>
      <h1>List of Customer</h1>
      {/* <div className=""> */}
      <SearchBox searchItem={'customer'} />
      <Card className="card-prod">
        <Card.Body>
          <Row>
            <Col xs={6} className="prod-col">
              <strong>Customer</strong>
            </Col>
            <Col xs={3} className="prod-col">
              <strong>Market</strong>
            </Col>
            {/* <Col xs={2} className="prod-col">
              <strong>CNIC</strong>
            </Col>
            <Col xs={2} className="prod-col">
              <strong>NTN</strong>
            </Col> */}
            <Col xs={3} className="prod-col">
              {/* <strong>
                {' '}
                <i className="fa-solid fa-cart-shopping"></i>
              </strong> */}
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {/* <div className="customers container"> */}
      {/* {loading ? (
          <LoadingBox />
        ) : error ? (
          <MessageBox variant="danger">{error}</MessageBox>
        ) :  */}
      {/* {
          <Row>
            {customers.map((customer) => (
              <Col key={customer.CsCode} sm={12} md={12} lg={12}>
                <Customer customer={customer}></Customer>
              </Col>
            ))}
          </Row>
        } */}
      {/* <div className="scroll-container" ref={ref}> */}
      {/* <ViewportList viewportRef={ref} items={customers} itemMinSize={40} margin={8}>
        {(item) => (
          <div key={item.Id} className="post">
              <Customer customer={item}></Customer>
          </div>
        )}
      </ViewportList> */}
      <List
        // itemSize={17}

        // width={window.innerWidth * 0.95}
        height={700}
        rowRenderer={renderCustomer}
        rowCount={filterdCustomers.length}
        rowHeight={50}
        width={
          window.innerWidth > 1000
            ? window.innerWidth * 0.675
            : window.innerWidth * 0.95
        }
      />
      {/* </div> */}
      {/* </div> */}
    </div>
  );
}

export default CustomerScreen;
