import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';
import HomeScreen from './screens/HomeScreen';
import ProductList from './screens/ProductList';
import ProductScreen from './screens/ProductScreen';
import CartScreen from './screens/CartScreen';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropDown from 'react-bootstrap/NavDropDown';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import { LinkContainer } from 'react-router-bootstrap';
import { useContext, useEffect, useState } from 'react';
import { Store } from './Store';
import SigninScreen from './screens/SigninScreen';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddCustomer from './screens/AddCustomer';
import CustomerScreen from './screens/CustomerScreen';
import PlaceOrderScreen from './screens/PlaceOrderScreen';
import OrderScreen from './screens/OrderScreen';
import OrderHistoryScreen from './screens/OrderHistoryScreen';
import PendingOrdersScreen from './screens/PendingOrdersScreen';
import SummaryCartScreen from './screens/SummaryCartScreen';
import SummaryHistoryScreen from './screens/SummaryHistoryScreen';
import SummaryScreen from './screens/SummaryScreen';
import SummaryItemsScreen from './screens/SummaryItemsScreen';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import { getError } from './util';
import SearchBox from './component/SearchBox';
import SearchScreen from './screens/SearchScreen';
import SearchCustomerScreen from './screens/SearchCustomerScreen';
import MainScreen from './screens/MainScreen';

//import './App.css';

function App() {
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { compcode, cart, userInfo } = state;

  const signoutHandler = () => {
    ctxDispatch({ type: 'USER_SIGNOUT' });
    localStorage.removeItem('userInfo');
    localStorage.removeItem('customerInfo');
    localStorage.removeItem('cartItems');
    localStorage.removeItem('cartOrders');
    localStorage.removeItem('customers');
    localStorage.removeItem('products');
    window.location.href = '/signin';
  };

  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);
  // const whatsAppMessage =
  //   'Please generate summary and proceed (' +
  //   userInfo.code +
  //   '-' +
  //   userInfo.name +
  //   ')';
  // const [categories, setCategories] = useState([]);
  // useEffect(() => {
  //   const fetchCategories = async () => {
  //     try {
  //       const { data } = await axios.get('/api/products/categories');
  //       setCategories(data);
  //     } catch (err) {
  //       toast.error(getError(err));
  //     }
  //   };
  //   fetchCategories();
  // }, []);
  return (
    <BrowserRouter>
      <div
        className={
          sidebarIsOpen
            ? 'd-flex flex-column site-container active-cont'
            : 'd-flex flex-column site-container'
        }
      >
        <ToastContainer position="bottom-center" limit={1} />
        <header>
          <Navbar bg="dark" variant="dark" expand="lg">
            <Container>
              {/* <Button
                variant="dark"
                onClick={() => setSidebarIsOpen(!sidebarIsOpen)}
              >
                <i className="fas fa-bars"></i>
              </Button> */}
              <Col xs={6}>
                <LinkContainer to="/">
                  <Navbar.Brand>Madni Booker App </Navbar.Brand>
                </LinkContainer>
              </Col>
              <Col
                xs={4}
                style={{
                  color: 'white',
                  textAlign: 'right',
                  fontSize: 'x-large',
                  fontWeight: '600',
                }}
              >
                {compcode}
              </Col>

              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                {/* <SearchBox /> */}
                <Nav className="me-auto w-100 justify-content-end">
                  <Link to="/cart" className="nav-link">
                    Cart
                    {cart.cartItems.length > 0 && (
                      <Badge pill bg="danger">
                        {cart.cartItems.reduce(
                          (a, c) => Number(a) + Number(c.quantity),
                          0
                        )}
                        {'+'}
                        {cart.cartItems.reduce(
                          (a, c) => Number(a) + Number(c.bonusqnty),
                          0
                        )}
                      </Badge>
                    )}
                  </Link>

                  {userInfo ? (
                    <NavDropDown title={userInfo.name} id="basic-nav-dropdown">
                      <LinkContainer to="/profile">
                        <NavDropDown.Item>User Profile</NavDropDown.Item>
                      </LinkContainer>
                      <LinkContainer to="/orderhistory">
                        <NavDropDown.Item>Order History</NavDropDown.Item>
                      </LinkContainer>
                      {/* <LinkContainer to="/summaryhistory">
                          <NavDropDown.Item>
                            Order Summary History
                          </NavDropDown.Item>
                        </LinkContainer> */}

                      <NavDropDown.Divider />
                      <Card.Link
                        className="dropdown-item"
                        to="#signout"
                        onClick={signoutHandler}
                      >
                        Sign Out
                      </Card.Link>
                    </NavDropDown>
                  ) : (
                    <Card.Link className="nav-link" to="/signin">
                      Sign In
                    </Card.Link>
                  )}
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </header>
        <div
          className={
            sidebarIsOpen
              ? 'active-nav side-navbar d-flex justify-content-between flex-wrap flex-column'
              : 'side-navbar d-flex justify-content-between flex-wrap flex-column'
          }
        >
          {/* <Nav className="flex-column text-white w-100 p-2">
            <Nav.Item>
              <strong>Companies</strong>
            </Nav.Item>
            {categories.map((category) => (
              <Nav.Item key={category.Cmp_Code}>
                <LinkContainer
                  to={{
                    pathname: '/search',
                    hash: '#hash',
                    search: `category=${category.Cmp_Code}`,
                  }}
                  onClick={() => setSidebarIsOpen(false)}
                >
                  <Nav.Link>{category.Cmp_Name}</Nav.Link>
                </LinkContainer>
              </Nav.Item>
            ))}
          </Nav> */}
        </div>
        <main>
          <Container className="mt-3">
            <Routes>
              <Route path="/" element={<MainScreen />} />
              <Route path="/signin" element={<SigninScreen />} />
              <Route path="/customer" element={<CustomerScreen />} />
              <Route
                path="/searchcustomer"
                element={<SearchCustomerScreen />}
              />
              <Route path="/products" element={<ProductList />} />
              <Route path="/product/:Id" element={<ProductScreen />} />
              <Route path="/cart" element={<CartScreen />} />
              <Route path="/search" element={<SearchScreen />} />
              <Route path="/placeorder" element={<PlaceOrderScreen />} />
              <Route path="/order/:id" element={<OrderScreen />} />
              <Route path="/orderhistory" element={<OrderHistoryScreen />} />
              <Route path="/pendingorders" element={<PendingOrdersScreen />} />
              <Route path="/summarycart" element={<SummaryCartScreen />} />
              <Route path="/summaryitems" element={<SummaryItemsScreen />} />
              <Route
                path="/summaryhistory"
                element={<SummaryHistoryScreen />}
              />
              <Route path="/summary/:id" element={<SummaryScreen />} />

              {/* <Route path="/signin" element={<SigninScreen />} /> */}
              {/* <Route path="/products" element={<HomeScreen />} /> */}
              <Route path="/addcustomer" element={<AddCustomer />} />
            </Routes>
          </Container>
        </main>
        <footer>
          <div className="text-center">All rights reserved</div>
        </footer>
      </div>
    </BrowserRouter>
  );
}

export default App;
