import Axios from 'axios';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet-async';
import CheckoutSteps from '../component/CheckoutSteps';
import LoadingBox from '../component/LoadingBox';
import MessageBox from '../component/MessageBox';
import ListGroup from 'react-bootstrap/ListGroup';
import { Store } from '../Store';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import ListGroupItem from 'react-bootstrap/esm/ListGroupItem';
import { toast } from 'react-toastify';
import { getError } from '../util';
import Badge from 'react-bootstrap/esm/Badge';
import NumberFormat from '../component/NumberFormat';

const reducer = (state, action) => {
  switch (action.type) {
    case 'CREATE_REQUEST':
      return { ...state, loading: true };
    case 'CREATE_SUCCESS':
      return { ...state, loading: false };
    case 'CREATE_FAIL':
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default function PlaceOrderScreen() {
  const navigate = useNavigate();
  const [{ loading }, dispatch] = useReducer(reducer, {
    loading: false,
  });

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { cart, userInfo } = state;
  const [error, setError] = useState('');

  useEffect(() => {
    if (!cart.customerInfo) {
      navigate('/customer');
    }
  }, [cart, navigate]);

  const round2 = (num) => Math.round(num * 100 + Number.EPSILON) / 100;
  cart.itemsPrice = 0;
  cart.itemsDiscount = 0;
  cart.itemsSTax = 0;

  // cart.orderTotal = round2(
  //   cart.cartItems.reduce((a, c) => a + c.quantity * c.ItemRate, 0)
  // );

  const placeOrderHandler = async () => {
    try {
      dispatch({ type: 'CREATE_REQUEST' });
      const { data } = await Axios.post(
        '/api/orders',
        {
          orderItems: cart.cartItems,
          customer: cart.customerInfo.CsCode,
          bookerUserId: userInfo.code,
        },
        {
          headers: {
            authorization: `Bearer ${userInfo.token}`,
          },
        }
      );

      ctxDispatch({ type: 'CART_CLEAR' });
      dispatch({ type: 'CREATE_SUCCESS' });
      localStorage.removeItem('cartItems');
      localStorage.removeItem('customerInfo');
      navigate(`/order/${data.order.BknghID}`);
    } catch (err) {
      dispatch({ type: 'CREATE_FAIL' });
      toast.error(getError(err));
      setError('Sign-In Again');
      localStorage.removeItem('userInfo');
      localStorage.removeItem('customers');
      localStorage.removeItem('products');
      ctxDispatch({ type: 'USER_SIGNIN_AGAIN' });
      navigate('/signin');
    }
  };

  return (
    <div>
      <CheckoutSteps step1 step2 step3 step4 step5 />
      <Helmet>
        <title>Preview Order</title>
      </Helmet>
      <div className="products-heading">
        <h1>Preview Order</h1>
        <Button href="/cart">
          <Link to="/cart" className="nav-link">
            <i className="fa-sharp fa-solid fa-cart-shopping"> </i>

            {cart.cartItems.length > 0 && (
              <Badge pill bg="danger">
                {cart.cartItems.length} /
                {cart.cartItems.reduce(
                  (a, c) => Number(a) + Number(c.quantity),
                  0
                )}
                {'+'}
                {cart.cartItems.reduce(
                  (a, c) => Number(a) + Number(c.bonusqnty),
                  0
                )}
              </Badge>
            )}
          </Link>
        </Button>
      </div>
      <Row>
        <Col md={8}>
          <Card>
            <Card.Body>
              <Card.Title>Order Summary</Card.Title>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <Row>
                    <Col col={1}>
                      <strong>Items</strong>
                    </Col>
                    {/* <Col>{cart.itemsPrice.toFixed(2)}</Col> */}
                    <Col col={1} style={{ textAlign: 'right' }}>
                      <strong>{cart.cartItems.length}</strong>
                    </Col>
                    <Col col={10}></Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col col={1}>
                      <strong>Quantity</strong>
                    </Col>
                    {/* <Col>{cart.itemsPrice.toFixed(2)}</Col> */}
                    <Col col={1} style={{ textAlign: 'right' }}>
                      <strong>
                        {cart.cartItems.reduce(
                          (a, c) => a + Number(c.quantity),
                          0
                        )}
                        {'+'}
                        {cart.cartItems.reduce(
                          (a, c) => a + Number(c.bonusqnty),
                          0
                        )}
                      </strong>
                    </Col>
                    <Col col={10}></Col>
                  </Row>
                </ListGroup.Item>
                {/* <ListGroup.Item>
                  <Row>
                    <Col>Discount</Col>
                    <Col>{cart.itemsDiscount.toFixed(2)}</Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col>S.Tax</Col>
                    <Col>{cart.itemsSTax.toFixed(2)}</Col>
                  </Row>
                </ListGroup.Item> */}
                <ListGroup.Item>
                  <Row>
                    <Col col={1}>
                      <strong>Order Total</strong>
                    </Col>
                    <Col
                      col={1}
                      style={{
                        textAlign: 'right',
                      }}
                    >
                      {/* <strong>{cart.orderTotal.toFixed(2)}</strong> */}
                      <strong>
                        <NumberFormat
                          value={cart.cartItems.reduce(
                            (a, c) => a + c.quantity * c.ItemRate,
                            0
                          )}
                          decimal={2}
                        ></NumberFormat>
                        /=
                      </strong>
                    </Col>
                    <Col col={10}></Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div>
                    <Button
                      type="button"
                      onClick={placeOrderHandler}
                      disabled={cart.cartItems.length === 0}
                    >
                      Place Order
                    </Button>
                  </div>
                  {loading ? (
                    <LoadingBox />
                  ) : error ? (
                    <MessageBox variant="danger">{error}</MessageBox>
                  ) : (
                    <div></div>
                  )}
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <Card>
            <Card.Body>
              <h3>Order Detail</h3>

              <Card.Title>Customer Information</Card.Title>
              <Card.Text>
                <Row>
                  <Col xs={3}>
                    <strong>Name :</strong>
                  </Col>
                  <Col>
                    <strong>
                      {' '}
                      {cart.customerInfo.CsCode} - {cart.customerInfo.CsName}
                    </strong>
                  </Col>
                </Row>
                <Row>
                  <Col xs={3}>
                    <strong>Address :</strong>
                  </Col>
                  <Col>{cart.customerInfo.CsAddrs}</Col>
                </Row>
                <Row>
                  <Col xs={3}>
                    <strong>CNIC :</strong>
                  </Col>
                  <Col>{cart.customerInfo.CsCNIC}</Col>
                </Row>
                <Row>
                  <Col xs={3}>
                    <strong>NTN :</strong>
                  </Col>
                  <Col>{cart.customerInfo.CsNTNO}</Col>
                </Row>
                <Row>
                  <Col xs={3}>
                    <strong>Market :</strong>
                  </Col>
                  <Col>
                    <strong>{cart.customerInfo.MarketName}</strong>
                  </Col>
                </Row>
              </Card.Text>
              <Link to="/customer">Change customers</Link>
            </Card.Body>
          </Card>
          <Card className="mb-3">
            <Card.Body>
              <Card.Title>Items Detail</Card.Title>
              <ListGroup variant="flush">
                {cart.cartItems.map((item) => (
                  <ListGroup.Item key={item.Id}>
                    <Row className="align-items-center">
                      <Col col={20}>{item.ItemDesc}</Col>
                      <Col col={1}>
                        {/* <span>{item.quantity}</span> */}
                        {item.quantity}
                        {item.bonusqnty > 0 ? '+' + item.bonusqnty : ''}
                      </Col>
                      <Col col={1}>
                        <NumberFormat
                          value={item.ItemRate}
                          decimal={2}
                        ></NumberFormat>
                      </Col>
                      <Col col={1}>
                        <NumberFormat
                          value={item.quantity * item.ItemRate}
                          decimal={2}
                        />
                      </Col>
                    </Row>
                  </ListGroup.Item>
                ))}
              </ListGroup>
              <Link to="/products">Change Items</Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
