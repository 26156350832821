import axios from 'axios';
import React, { useEffect, useReducer, useState, useContext } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import { Helmet } from 'react-helmet-async';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingBox from '../component/LoadingBox';
import MessageBox from '../component/MessageBox';
import { getError } from '../util';
import Product from '../component/Product';
import Customer from '../component/Customer';
import { Store } from '../Store';
import LinkContainer from 'react-router-bootstrap/LinkContainer';
import CheckoutSteps from '../component/CheckoutSteps';
import SearchBox from '../component/SearchBox';
import { List } from 'react-virtualized';

// const reducer = (state, action) => {
//   switch (action.type) {
//     case 'FETCH_REQUEST':
//       return { ...state, loading: true };
//     case 'FETCH_SUCCESS':
//       return {
//         ...state,
//         customers: action.payload,
//         loading: false,
//       };
//     case 'FETCH_FAIL':
//       return { ...state, loading: false, error: action.payload };
//     default:
//       return state;
//   }
// };

export default function SearchScreen() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const sp = new URLSearchParams(search); /// search?category=Pharma
  const query = sp.get('query') || 'all';
  const { state } = useContext(Store);
  const { compcode, userInfo, customers } = state;

  const countCustomers = customers ? customers.length : 0;
  const searchedCustomers = customers.filter(
    (customer) =>
      (customer.CsName.toLowerCase().includes(
        query === 'all' ? customer.CsName.toLowerCase() : query.toLowerCase()
      ) ||
        customer.CsCode.toLowerCase().includes(
          query === 'all' ? customer.CsCode.toLowerCase() : query.toLowerCase()
        )) &&
      customer.CsMisc === (compcode === 'ME' ? 'N' : customer.CsMisc)
  );

  //setFilteredCustomers(result);
  //setFilteredCustomers([]);

  // const [customers, setCustomers] = useState(() => {
  //   const savedItem = localStorage.getItem('customers');
  //   const parsedItem = JSON.parse(savedItem);
  //   return parsedItem || '';
  // });
  // const countCustomers = customers.lenght;

  // const [{ loading, error, customers, countCustomers }, dispatch] = useReducer(
  //   reducer,
  //   {
  //     loading: true,
  //     error: '',
  //   }
  // );

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const { data } = await axios.get(
  //         `/api/customers/search?query=${query}`
  //       );
  //       dispatch({ type: 'FETCH_SUCCESS', payload: data });
  //     } catch (err) {
  //       dispatch({
  //         type: 'FETCH_FAIL',
  //         payload: getError(err),
  //       });
  //     }
  //   };
  //   fetchData();
  // }, [customers, error, query]);
  useEffect(() => {
    if (!userInfo || !customers || countCustomers === 0) {
      navigate('/signin');
    }
  }, [navigate, userInfo, customers, countCustomers]);

  const renderSearchedCustomer = ({ index, key, style }) => (
    <div key={key} style={style} className="post">
      {/* <h3>{`${products[index].ItemDesc}-${products[index].Id}`}</h3>
        <p>{products[index].body}</p> */}
      <Customer customer={searchedCustomers[index]}></Customer>
    </div>
  );

  return (
    <div>
      <CheckoutSteps step1 step2></CheckoutSteps>
      <Helmet>
        <title>Search Customers</title>
      </Helmet>
      <h1>List of Search Customer</h1>
      <SearchBox searchItem={'customer'} />
      {/* <Row>
        <Col md={12}> */}
      {/* {loading ? (
            <LoadingBox></LoadingBox>
          ) : error ? (
            <MessageBox variant="danger">{error}</MessageBox>
          ) :  */}
      {
        <div>
          <Row className="justify-content-between mb-3">
            <Col md={6}>
              <div>
                {countCustomers === 0 ? 'No' : countCustomers} Results
                {query !== 'all' && ' : ' + query}
                {
                  <Button variant="light" onClick={() => navigate('/customer')}>
                    <i className="fas fa-times-circle"></i>
                  </Button>
                }
                {/* {query !== 'all' ? (
                      <Button
                        variant="light"
                        onClick={() => navigate('/customer')}
                      >
                        <i className="fas fa-times-circle"></i>
                      </Button>
                    ) : null} */}
              </div>
            </Col>
          </Row>
          {customers.lenght === 0 && <MessageBox>No Customer Found</MessageBox>}
          <Card className="card-prod">
            <Card.Body>
              <Row>
                <Col xs={6} className="prod-col">
                  <strong>Customer</strong>
                </Col>
                <Col xs={3} className="prod-col">
                  <strong>Market</strong>
                </Col>
                {/* <Col xs={2} className="prod-col">
              <strong>CNIC</strong>
            </Col>
            <Col xs={2} className="prod-col">
              <strong>NTN</strong>
            </Col> */}
                <Col xs={3} className="prod-col">
                  {/* <strong>
                {' '}
                <i className="fa-solid fa-cart-shopping"></i>
              </strong> */}
                </Col>
              </Row>
            </Card.Body>
          </Card>
          {/* <Row>
                {customers.map((customer) => (
                  // <Col sm={6} lg={4} className="mb-3" key={product.Id}>
                  <Col key={customer.Id} sm={12} md={12} lg={12}>
                    <Customer customer={customer}></Customer>
                  </Col>
                ))}
              </Row> */}
          {/* <Row>
                {customers
                  .filter(
                    (customer) =>
                      customer.CsName.toLowerCase().includes(
                        query === 'all'
                          ? customer.CsName.toLowerCase()
                          : query.toLowerCase()
                      ) ||
                      customer.CsCode.toLowerCase().includes(
                        query === 'all'
                          ? customer.CsCode.toLowerCase()
                          : query.toLowerCase()
                      )
                  )
                  .map((customer) => (
                    // <Col sm={6} lg={4} className="mb-3" key={product.Id}>
                    <Col key={customer.Id} sm={12} md={12} lg={12}>
                      <Customer customer={customer}></Customer>
                    </Col>
                  ))}
              </Row> */}
          <List
            // itemSize={17}

            // width={window.innerWidth * 0.95}
            height={700}
            rowRenderer={renderSearchedCustomer}
            rowCount={searchedCustomers.length}
            rowHeight={50}
            width={
              window.innerWidth > 1000
                ? window.innerWidth * 0.675
                : window.innerWidth * 0.95
            }
          />
        </div>
      }
      {/* </Col>
      </Row> */}
    </div>
  );
}
