import axios from 'axios';
import React, { useContext, useEffect, useReducer } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate, useParams } from 'react-router-dom';
import LoadingBox from '../component/LoadingBox';
import MessageBox from '../component/LoadingBox';
import { Store } from '../Store';
import { getError } from '../util';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/esm/Button';
import NumberFormat from '../component/NumberFormat';
import dateFormat from 'dateformat';

function reducer(state, action) {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true, error: '' };
    case 'FETCH_SUCCESS':
      return { ...state, loading: false, order: action.payload, error: '' };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}

export default function OrderScreen() {
  const { state } = useContext(Store);
  const { userInfo } = state;
  const params = useParams();
  const navigate = useNavigate();
  const { id: orderId } = params;

  const [{ loading, error, order }, dispatch] = useReducer(reducer, {
    loading: true,
    order: {},
    error: '',
  });

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        dispatch({ type: 'FETCH_REQUEST' });
        const { data } = await axios.get(`/api/orders/${orderId}`, {
          headers: { authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: 'FETCH_SUCCESS', payload: data });
      } catch (err) {
        dispatch({ type: 'FETCH_FAIL', payload: getError(err) });
      }
    };

    if (!userInfo) {
      return navigate('/signin');
    }

    if (!order.id || (order.id && order.id !== orderId)) {
      fetchOrder();
    }
  }, [order, userInfo, orderId, navigate]);

  return loading ? (
    <LoadingBox></LoadingBox>
  ) : error ? (
    <MessageBox variant="danger">{error}</MessageBox>
  ) : (
    <div>
      <Helmet>
        <title>Order Information {order.order[0].BknghRefNo}</title>
      </Helmet>
      <Button
        type="button"
        onClick={() => {
          navigate('/customer');
        }}
        // disabled={cart.cartItems.length === 0}
      >
        New Order
      </Button>

      <h6 className="my-3" style={{ color: 'green' }}>
        Order successfully created! 👍
      </h6>
      <h6 className="my-3" style={{ color: 'green' }}>
        Generated order number : {order.order[0].BknghRefNo}
      </h6>
      <h6 className="my-3" style={{ color: 'green' }}>
        Generated order Date & Time : {order.order[0].BknghRefDate}
      </h6>
      <h6>Order Inforamtion</h6>
      <Row>
        <Col xs={12}>
          {/* <div>
            <Row>
              <Col md={1}></Col>
              <Col md={3}>
                <strong>Customer Name:</strong>
              </Col>
              <Col>{order.order[0].CsName}</Col>
            </Row>
          </div> */}
          {/* <Card>
            <Card.Body>
              <Card.Title>
                <Card.Text>
                    <Row key={order.order[0].BknghId}>
                      <Col md={2}>
                        <strong>Name:</strong>
                      </Col>
                      <Col>{order.order[0].CsName}</Col>
                    </Row>
                </Card.Text>
              </Card.Title>
            </Card.Body>
          </Card> */}
          <Card>
            <Card.Body>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <Row>
                    <h5>
                      <Col xs={12}>{order.order[0].CsName}</Col>
                    </h5>
                  </Row>
                  <Row>
                    <Col xs={12}>{order.order[0].CsAddrs}</Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <h6>Order Items Detail</h6>
                </ListGroup.Item>
                {/* <Row>
                  <Col md={1}></Col>
                  <Col md={3}>
                    <strong>Customer Name:</strong>
                  </Col>
                  <Col>{order.order[0].CsName}</Col>
                </Row> */}
                <ListGroup.Item>
                  {order.order.map((item) => (
                    <div key={item.ItemCode}>
                      <Row className="align-items-center">
                        <Col xs={3}>{item.ItemDesc}</Col>
                        <Col xs={3}>
                          <span>
                            <NumberFormat value={item.BkngdQnty} decimal={2} />
                          </span>
                        </Col>
                        <Col xs={3}>
                          <NumberFormat value={item.BkngdTpRate} decimal={2} />
                        </Col>
                        <Col xs={3}>
                          <NumberFormat value={item.BkngdAmnt} decimal={2} />
                        </Col>
                      </Row>
                    </div>
                  ))}
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
